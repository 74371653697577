<template>
    <div>
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <!-- 알림, 메뉴 아이콘 -->
            <div
                class="d-flex justify-end pt-6 pb-4 px-2"
            >
                <v-icon size="20" class="mr-4" style="cursor:pointer;">mdi-bell-outline</v-icon>
                <v-icon style="cursor:pointer;" @click="drawer = !drawer">mdi-menu</v-icon>
            </div>

            <!-- 상단 -->
            <div
                style="position:relative;"
            >
                <!-- 제목 -->
                <p
                    class="text-body-2 mb-2 font-weight-bold grey--text text--darken-2"
                >
                    스윗딜
                </p>

                <!-- 부제목 -->
                <p
                    class="text-h6 font-weight-bold"
                    style="line-height:28px;"
                >
                    <span class="primary--text">고객센터</span>
                </p>
            </div>

            <v-sheet
                class="pt-16 pb-8 mt-10 rounded-xl"
            >
                <!-- 로고 -->
                <v-img
                    class="mx-auto mb-4"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <!-- 안내문구 -->
                <p
                    class="mx-10 font-weight-medium mb-6 text-center" style="line-height:28px;"
                >
                    <span class="primary--text">keynes30@naver.com</span><br/>
                    <span class="text-body-2 font-weight-medium">문의주시면 상세히 답변드리겠습니다</span>
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            style="position:fixed; z-index:999;"
        >
            <SideMenu />
        </v-navigation-drawer>
    </div>
</template>
<script>
import SideMenu from './components/SideMenu'

export default {
    data: () => ({
        drawer: false,
    }),

    components: {
        SideMenu
    }
}
</script>