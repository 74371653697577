<template>
    <div
        class="pb-16"
    >
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <!-- 알림, 메뉴 아이콘 -->
            <div
                class="d-flex justify-end pt-6 pb-4 px-2"
            >
                <v-icon size="20" class="mr-4" style="cursor:pointer;">mdi-bell-outline</v-icon>
                <v-icon style="cursor:pointer;" @click="drawer = !drawer">mdi-menu</v-icon>
            </div>

            <!-- 상단 -->
            <div
                style="position:relative;"
            >
                <!-- 제목 -->
                <p
                    class="text-body-2 mb-2 font-weight-bold grey--text text--darken-2"
                >
                    스윗딜
                </p>

                <!-- 부제목 -->
                <p
                    class="text-h6 font-weight-bold"
                    style="line-height:28px;"
                >
                    <span class="primary--text">회원정보 변경</span>
                </p>
            </div>

            <v-sheet
                class="pt-16 pb-6 px-4 mt-10 rounded-xl"
            >
                <!-- 프로필 이미지 & 이미지 수정 -->
                <v-sheet class="d-flex justify-center mb-2">
                    <!-- 뱃지 -->
                    <v-badge
                        color="transparent"
                        offset-x="20"
                        offset-y="20"
                    >
                        <!-- 프로필 이미지 -->
                        <v-img
                            class="rounded-circle"
                            style="width:80px; height:80px; cursor:pointer;"
                            :src="'/upload/user/profile/' + user.profile_image"
                        ></v-img>
                            <!-- @click="dialog.updateImage = true" -->

                        <!-- 뱃지 아이콘 -->
                        <!-- <template v-slot:badge>
                            <v-icon>mdi-cog</v-icon>
                        </template> -->
                    </v-badge>

                    <!-- 프로필 이미지 수정 -->
                    <v-dialog
                        v-model="dialog.updateImage"
                        width="auto"
                    >
                        <ProfileImage
                            @imageUpdated="load(); dialog.updateImage = false"
                        />
                    </v-dialog>
                </v-sheet>
            
                <table class="custom_table mx-auto px-2 mt-10">
                    <tr>
                        <td class="pb-7">실명</td>
                        <td>
                            <v-text-field
                                class="ml-2 mb-8 rounded-10"
                                style="background:#f5f5f5;"
                                outlined
                                dense
                                readonly
                                hide-details
                                :placeholder="$store.state.sweetdeal_user.name"
                            ></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">아이디</td>
                        <td>
                            <v-text-field
                                class="ml-2 mb-8 rounded-10"
                                style="background:#f5f5f5;"
                                outlined
                                dense
                                readonly
                                hide-details
                                :placeholder="$store.state.sweetdeal_user.user_id"
                            ></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">카카오톡 동의</td>
                        <td
                            class="pb-7"
                        >
                            <div
                                class="d-flex justify-center"
                            >
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    dense
                                    :label="marketing_kakao? '동의':'거부'"
                                    v-model="marketing_kakao"
                                    @change="update_marketing_kakao()"
                                ></v-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">SMS 동의</td>
                        <td
                            class="pb-7"
                        >
                            <div
                                class="d-flex justify-center"
                            >
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    dense
                                    :label="marketing_sms? '동의':'거부'"
                                    v-model="marketing_sms"
                                    @change="update_marketing_sms()"
                                ></v-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">앱 PUSH 동의</td>
                        <td
                            class="pb-7"
                        >
                            <div
                                class="d-flex justify-center"
                            >
                                <v-switch
                                    class="mt-0"
                                    hide-details
                                    dense
                                    :label="marketing_app? '동의':'거부'"
                                    v-model="marketing_app"
                                    @change="update_marketing_app()"
                                ></v-switch>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">현재 비밀번호<font class="ml-1 red--text">*</font></td>
                        <td>
                            <v-text-field
                                class="ml-2 rounded-10"
                                outlined
                                dense
                                persistent-placeholder
                                type="password"
                                v-model="password_prev"
                                @change="$v.password_prev.$touch()"
                                @blur="$v.password_prev.$touch()"
                                :error-messages="password_prev_Errors"
                            ></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">새 비밀번호</td>
                        <td>
                            <v-text-field
                                class="ml-2 rounded-10"
                                outlined
                                dense
                                persistent-placeholder
                                type="password"
                                v-model="password_next"
                                @change="$v.password_next.$touch()"
                                @blur="$v.password_next.$touch()"
                                :error-messages="password_next_Errors"
                            ></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td class="pb-7">새 비밀번호 확인</td>
                        <td>
                            <v-text-field
                                class="ml-2 rounded-10"
                                outlined
                                dense
                                persistent-placeholder
                                type="password"
                                v-model="password_check"
                                @change="$v.password_check.$touch()"
                                @blur="$v.password_check.$touch()"
                                :error-messages="password_check_Errors"
                            ></v-text-field>
                        </td>
                    </tr>
                </table>

                <!-- 제출 -->
                <v-sheet class="mb-6 d-flex justify-center">
                    <v-btn
                        @click="submit()"
                        class="rounded-10 mx-2 px-10"
                        large
                        dark
                        depressed
                        color="primary"
                    >
                        비밀번호 수정하기
                    </v-btn>
                </v-sheet>

                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        width="120"
                        class="grey--text text--darken-1 rounded-lg py-4 mr-2"
                        color="grey lighten-2"
                        depressed
                        small
                        dark
                        to="/auth/logout"
                    >
                        로그아웃
                    </v-btn>
                    <v-btn
                        width="120"
                        class="grey--text text--darken-1 rounded-lg py-4"
                        color="grey lighten-2"
                        depressed
                        small
                        @click="leaveSubmit()"
                    >
                        회원탈퇴하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            style="position:fixed; z-index:999;"
        >
            <SideMenu />
        </v-navigation-drawer>
    </div>
</template>
<script>
import SideMenu from './components/SideMenu'
import ProfileImage from './components/ProfileImage'

// 검증
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        SideMenu,
        ProfileImage
    },

    mixins: [validationMixin],

    validations: {
        password_prev: {
            required,
            minLength: minLength(8)
        },

        password_next: {
            minLength: minLength(8)
        },

        password_check: {
            sameAsPassword: sameAs('password_next')
        }
    },

    data: () => ({
        drawer: false,

        name_list: [],

        password_prev: "",
        password_next: "",
        password_check: "",
        name: "",
        marketing_kakao: false,
        marketing_sms: false,
        marketing_app: false,

        user: {
            profile_image: "",
        },

        dialog: {
            updateImage: false
        },
    }),

    computed: {
        // 현재 비밀번호 Error 문구
        password_prev_Errors () {
            const errors = []
            if (!this.$v.password_prev.$dirty) return errors
            !this.$v.password_prev.required && errors.push('현재 비밀번호를 입력해주세요')
            !this.$v.password_prev.minLength && errors.push('패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 Error 문구
        password_next_Errors () {
            const errors = []
            if (!this.$v.password_next.$dirty) return errors
            !this.$v.password_next.minLength && errors.push('패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 신규 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.sameAsPassword && errors.push('신규 비밀번호 확인이 같지 않습니다')
            return errors
        }
    },

    mounted(){
        // 유저정보 불러오기
        this.load()
    },

    methods: {
        // 유저정보 불러오기
        load(){
            this.$http.post("/api/user/select/specific", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id
                }
            }).then((res) => {
                this.user.profile_image = res.data[0].profile_image
                this.name = res.data[0].name
                this.marketing_kakao = res.data[0].marketing_kakao
                this.marketing_sms = res.data[0].marketing_sms
                this.marketing_app = res.data[0].marketing_app
            })
        },

        // 카카오톡 동의 수정하기
        update_marketing_kakao(){
            this.$http.post("/api/user/update/marketing_kakao", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id,
                    marketing_kakao: this.marketing_kakao
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.$globalSnackbar.show(`카카오톡 마케팅 수신 동의가 ${this.marketing_kakao? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // SMS 동의 수정하기
        update_marketing_sms(){
            this.$http.post("/api/user/update/marketing_sms", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id,
                    marketing_sms: this.marketing_sms
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.$globalSnackbar.show(`SMS 마케팅 수신 동의가 ${this.marketing_sms? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // 앱 PUSH 동의 수정하기
        update_marketing_app(){
            this.$http.post("/api/user/update/marketing_app", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id,
                    marketing_app: this.marketing_app
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.$globalSnackbar.show(`앱 PUSH 마케팅 수신 동의가 ${this.marketing_app? "동의":"거부"}처리되었습니다`)
                    this.password_prev = ""
                    this.password_next = ""
                    this.password_check = ""
                    this.load()
                }
            })
        },

        // 수정하기
        submit: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.$store.state.sweetdeal_user.user_id,
                        password:this.password_prev
                    }
                }).then((res) => {
                    if(!res.data[0]){
                        this.$globalSnackbar.show("현재 비밀번호가 맞지 않습니다.")
                    }else{
                        this.$http.post("/api/user/update/password", {
                            params: {
                                user_id: this.$store.state.sweetdeal_user.user_id,
                                password_next: this.password_next
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                this.$globalSnackbar.show("회원정보가 수정되었습니다.")
                                this.password_prev = ""
                                this.password_next = ""
                                this.password_check = ""
                                this.load()
                            }
                        })
                    }
                })
            }
        }, 500),

        // 회원탈퇴하기
        leaveSubmit(){
            this.$http.post("/api/user/delete", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.$globalSnackbar.show("회원탈퇴가 완료되었습니다.")
                    this.$router.push('/auth/logout')
                }
            })
        }
    }
}
</script>
<style scoped>
/* 리스트 기본 이펙트 제거 */
::v-deep .v-list-item:before,
::v-deep .v-list-item:hover:before,
::v-deep .v-list-item--active:before,
::v-deep .v-list-item--active:hover:before,
::v-deep .v-list-item:focus:before {
    opacity: 0;
}
</style>
<style scoped>
/* 검색 keyword */
::v-deep .v-text-field input {
    margin-left:8px;
    font-size: 18px;
}

/* 검색 type */
::v-deep .v-select input {
    margin-left:8px;
    font-size: 18px;
}

/* 입력란 공통 (fieldset) */
.v-text-field--outlined >>> fieldset {
  border-color: #E0E0EB;
}

/* 입력란 공통 (label) */
.v-text-field--outlined >>> label {
    color: #C3BDD1;
    font-size:13px;
    font-weight:400;
}

/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.custom_table tr td{
    padding:0px;
}

.custom_table tr td:first-child{
    width:120px;
    font-weight:bold;
}

</style>