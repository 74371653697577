<template>
    <div>
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <!-- 알림, 메뉴 아이콘 -->
            <div
                class="d-flex justify-end pt-6 pb-4 px-2"
            >
                <v-icon size="20" class="mr-4" style="cursor:pointer;">mdi-bell-outline</v-icon>
                <v-icon style="cursor:pointer;" @click="drawer = !drawer">mdi-menu</v-icon>
            </div>

            <!-- 상단 -->
            <div
                style="position:relative;"
            >
                <!-- 제목 -->
                <p
                    class="text-h6 font-weight-bold pt-6 mb-8 grey--text text--darken-3"
                    style="line-height:28px;"
                >
                    <span v-if="$route.query.type == 'ongoing'">쳬결된 계약</span>
                    <span v-if="$route.query.type == 'end'">종료된 계약</span>
                    <span v-if="$route.query.type == 'mysignwaiting'">내 서명 대기중</span>
                    <span v-if="$route.query.type == 'partnersignwaiting'">상대방 서명 대기중</span>
                </p>

                <!-- 로고 -->
                <v-img
                    style="position:absolute; top:0px; right:4px;"
                    width="84"
                    src="@/assets/logo/default.png"
                ></v-img>
            </div>

            <!-- 내 서명 대기중 -->
            <v-sheet
                v-if="$route.query.type == 'mysignwaiting'"
                class="py-4"
                color="transparent"
            >
                <div v-if="list.mysignwaiting.length">
                    <v-sheet
                        v-for="(item, index) in list.mysignwaiting" :key="index+'my'"
                        class="py-4 rounded-xl mb-3"
                        @click="viewContract(item)"
                    >
                        <p
                            class="mx-4 font-weight-medium mb-0 pl-4 text-body-2"
                            style="line-height:22px;"
                        >
                            <span class="primary--text">{{item.sender}}님과의 계약에 내 서명 대기중</span><br/>
                            <span class="grey--text text-caption">{{new Date(item.created).toLocaleString()}}</span>
                        </p>
                    </v-sheet>
                </div>
            </v-sheet>

            <!-- 상대방 서명 대기중 -->
            <v-sheet
                v-if="$route.query.type == 'partnersignwaiting'"
                class="py-4"
                color="transparent"
            >
                <div v-if="list.partnersignwaiting.length">
                    <v-sheet
                        v-for="(item, index) in list.partnersignwaiting" :key="index+'my'"
                        class="py-4 rounded-xl mb-3"
                        @click="viewContract(item)"
                    >
                        <p
                            class="mx-4 font-weight-medium mb-0 pl-4 text-body-2"
                            style="line-height:22px;"
                        >
                            <span class="primary--text">{{item.receiver}}님과의 계약에 상대방 서명 대기중</span><br/>
                            <span class="grey--text text-caption">{{new Date(item.created).toLocaleString()}}</span>
                        </p>
                    </v-sheet>
                </div>
            </v-sheet>

            <!-- 쳬결된 계약 -->
            <v-sheet
                v-if="$route.query.type == 'ongoing'"
                class="py-4 mt-12 rounded-xl"
                color="transparent"
            >
                <v-sheet
                    v-for="(item, index) in list.on" :key="index+'on'"
                    class="py-4 rounded-xl mb-3"
                    @click="viewContract(item)"
                >
                    <p
                        class="mx-4 font-weight-medium mb-0 pl-4 text-body-2"
                        style="line-height:22px;"
                    >
                        <span class="primary--text">{{item.sender}}</span>님과 <span class="primary--text">{{item.receiver}}</span>님께서<br/>
                        {{new Date(item.created).toLocaleString()}}<br/> 계약을 체결했어요!<br/>
                        <v-switch
                            v-model="item.reveal"
                            hide-details
                            label="공개"
                            class="mt-1 mb-0"
                            @click="updateReveal(item)"
                        ></v-switch>
                    </p>
                </v-sheet>
            </v-sheet>

            <!-- 종료된 계약 -->
            <v-sheet
                v-if="$route.query.type == 'end'"
                class="py-6 mt-12 rounded-xl"
            >
                <p
                    class="text-center text-body-2 font-weight-medium mb-0"
                >
                    아직 종료된 계약서가 없습니다
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            style="position:fixed; z-index:999;"
        >
            <SideMenu />
        </v-navigation-drawer>

        <v-dialog
            v-model="dialog.viewer"
            width="680"
            content-class="rounded-10"
        >
            <ContractViewer
                :id="viewer_id"
                :sign="sign"                
                :key="componentKey"
            />
        </v-dialog>
    </div>
</template>
<script>
import SideMenu from './components/SideMenu'
import ContractViewer from "@/components/ContractViewer"

export default {
    components: {
        SideMenu,
        ContractViewer
    },
    
    data: () => ({
        drawer: false,

        list: {
            mysignwaiting: [],
            partnersignwaiting: [],
            on: []
        },

        dialog: {
            viewer: false
        },

        sign: false,

        viewer_id: 0,
        componentKey: 0
    }),

    mounted(){
        // 계약 전체 불러오기
        this.loadContract()
    },

    methods: {
        // 계약 전체 불러오기
        loadContract(){
            this.$http.post("/api/contract/select")
            .then((res) => {
                if(res.data.length){
                    this.list.mysignwaiting = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.partnersignwaiting = res.data.filter(e => e.sender == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.on = res.data.filter(e => e.status == '계약완료' && (e.sender == this.$store.state.sweetdeal_user.name || e.receiver == this.$store.state.sweetdeal_user.name))
                }
            })
        },

        // 계약서 공개유무 수정하기
        updateReveal(item){
            this.$http.post("/api/contract/update/reveal", {
                params: {
                    id: item.id,
                    reveal: item.reveal
                }
            })
        },

        // 계약서 상세보기
        viewContract(item){
            this.viewer_id = item.id

            if(item.receiver == this.$store.state.sweetdeal_user.name){
                this.sign = true
            }else{
                this.sign = false
            }

            this.componentKey++
            this.dialog.viewer = true
        }
    }
}
</script>