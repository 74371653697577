<template>
    <v-card
        class="mx-auto"
        width="256"
        tile
        color="#313942"
    >
        <!-- 로고 (white) -->
        <v-img
            width="80"
            class="mx-auto mt-6"
            src="@/assets/logo/white.png"
        ></v-img>

        <!-- 제목 -->
        <p class="text-caption text-center white--text mb-0">
            SweetDeal
        </p>

        <!-- 부제목 -->
        <p class="text-center white--text mt-2 mb-2">
            관리자 페이지
        </p>

        <!-- 구분선 -->
        <v-divider color="grey" class="mx-16"></v-divider>
        
        <!-- 관리자 로그아웃 -->
        <div class="d-flex justify-center my-6">
            <v-btn
                v-if="$store.state.sweetdeal_admin.is_logined"
                class="rounded-lg"
                color="grey lighten-1"
                outlined
                to="/admin/auth/logout"
            >
                관리자 로그아웃
            </v-btn>
        </div>

        <!-- 메뉴 그룹 -->
        <v-list
            v-if="$store.state.sweetdeal_admin.is_logined"
            nav
            dense
            color="#313942"
        >
            <v-list-item-group>
                <!-- 메뉴 -->
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    :to="item.link"
                    class="py-1 px-3 my-1"
                    :class="$route.path == item.link? 'primary':''"
                >
                    <!-- 아이콘 -->
                    <v-list-item-icon>
                        <v-icon color="white">{{item.icon}}</v-icon>
                    </v-list-item-icon>

                    <!-- 제목 -->
                    <v-list-item-content>
                        <v-list-item-title class="white--text">{{item.text}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-card>
</template>
<script>
export default {
    data: () => ({
        menu: [
            { 
                text: "이벤트 관리",
                icon: "mdi-cake-variant-outline",
                link: "/admin/event/list"
            },
            { 
                text: "키워드 관리",
                icon: "mdi-script-text-outline",
                link: "/admin/keyword"
            },
            { 
                text: "계약 관리",
                icon: "mdi-file-sign",
                link: "/admin/contract"
            },
            { 
                text: "위시리스트 관리",
                icon: "mdi-invoice-edit-outline",
                link: "/admin/wishlist"
            },
            { 
                text: "마지노선 관리",
                icon: "mdi-vector-line",
                link: "/admin/maginotline"
            },
            { 
                text: "회원 관리",
                icon: "mdi-account-circle-outline",
                link: "/admin/user"
            },
            { 
                text: "AI 관리",
                icon: "mdi-creation-outline",
                link: "/admin/ai"
            },
            { 
                text: "관리자 정보수정",
                icon: "mdi-shield-edit-outline",
                link: "/admin/update"
            }
        ]
    })
}
</script>