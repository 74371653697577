<template>
    <div>
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <div
                class="d-flex justify-end pt-6 pb-4 px-2"
            >
                <!-- 벨 모양 -->
                <v-icon size="20" class="mr-4" style="cursor:pointer;">mdi-bell-outline</v-icon>

                <!-- 사이드 메뉴 -->
                <v-icon style="cursor:pointer;" @click="drawer = !drawer">mdi-menu</v-icon>
            </div>

            <!-- 상단 -->
            <div
                style="position:relative;"
            >
                <!-- 제목 -->
                <p
                    class="text-body-2 mb-2 font-weight-bold grey--text text--darken-2"
                >
                    스윗딜
                </p>
                <p
                    class="text-h6 font-weight-bold"
                    style="line-height:28px;"
                >
                    <span class="primary--text">{{$store.state.sweetdeal_user.name}}</span>님, 환영합니다.
                </p>

                <!-- 로고 -->
                <v-img
                    style="position:absolute; top:0px; right:4px;"
                    width="84"
                    src="@/assets/logo/default.png"
                ></v-img>
            </div>

            <!-- 대기중인 계약 -->
            <div
                style="height:60px;"
            >
                <v-btn
                    v-if="list.mysignwaiting.length"
                    class="rounded-lg pr-4 mb-2"
                    color="primary"
                    depressed
                    x-small
                    height="28"
                    @click="$router.push('/mypage/list?type=mysignwaiting')"
                >
                    ※ 내 서명 대기중인 계약이 {{list.mysignwaiting.length}}건이 있습니다
                </v-btn>
                <v-btn
                    v-if="list.partnersignwaiting.length"
                    class="rounded-lg pr-4"
                    color="#fe7f5e"
                    dark
                    depressed
                    x-small
                    height="28"
                    @click="$router.push('/mypage/list?type=partnersignwaiting')"
                >
                    ※ 상대방 서명 대기중인 계약이 {{list.partnersignwaiting.length}}건이 있습니다
                </v-btn>
            </div>

            <!-- 체결한 계약서가 있을 경우 -->
            <v-sheet
                v-if="list.on.length"
                class="pt-16 pb-8 mt-4 rounded-xl"
            >
                <v-img
                    class="mx-auto mb-4"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <p
                    class="mx-10 font-weight-medium mb-6 text-center" style="line-height:28px;"
                >
                    <span class="primary--text">{{last_list_on.sender}}</span>님과 <span class="primary--text">{{last_list_on.receiver}}</span>님의 연인계약<br/>
                    <span class="primary--text">{{Math.floor((new Date() - new Date(last_list_on.created)) / (1000 * 60 * 60 * 24))}}일째</span>
                </p>

            </v-sheet>

            <!-- 체결한 계약서가 없을 경우 -->
            <v-sheet
                v-if="!list.on.length"
                class="pt-16 pb-8 mt-10 rounded-xl"
            >
                <v-img
                    class="mx-auto mb-4"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <p
                    class="mx-10 font-weight-medium mb-6 text-center" style="line-height:28px;"
                >
                    <span class="primary--text">아직 체결한 계약서가 없습니다</span>
                </p>
            </v-sheet>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            style="position:fixed; z-index:999;"
        >
            <SideMenu />
        </v-navigation-drawer>
    </div>
</template>
<script>
import SideMenu from './components/SideMenu'

export default {
    components: {
        SideMenu
    },

    data: () => ({
        drawer: false,

        list: {
            mysignwaiting: [],
            partnersignwaiting: [],
            on: []
        },

        last_list_on: {}
    }),

    mounted(){
        // 로그인 확인
        if(!this.$store.state.sweetdeal_user.is_logined){
            this.$globalSnackbar.show("마이페이지는 로그인 이후 이용가능합니다.")
            this.$router.push("/")
        }

        // 계약 불러오기
        this.loadContract()
    },

    methods: {
        // 계약 불러오기
        loadContract(){
            this.$http.post("/api/contract/select")
            .then((res) => {
                if(res.data.length){
                    this.list.mysignwaiting = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.partnersignwaiting = res.data.filter(e => e.sender == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.on = res.data.filter(e => e.status == '계약완료' && (e.sender == this.$store.state.sweetdeal_user.name || e.receiver == this.$store.state.sweetdeal_user.name))

                    // 쳬결된 계약중 가장 마지막 계약
                    this.last_list_on = this.list.on[this.list.on.length -1]
                }
            })
        }
    }
}
</script>