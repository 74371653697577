<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile & !loading"
            class="pa-4 rounded-15"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h5 font-weight-bold mb-2 pt-5 pl-4"
                style="line-height:28px;"
            >
                <span class="primary--text">마지노선</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:28px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 본문 -->
            <v-sheet
                class="mb-8 rounded-10 pt-2 pb-4 px-4"
            >
                <!-- 마지노선 조항 -->
                <div v-for="(item, index) in clause_list" :key="index">
                    <p
                        class="mt-10 mb-4 text-subtitle-1 font-weight-bold grey--text text--darken-1"
                        style="line-height:20px;"
                    >
                        {{index+1}}. {{item.line}}
                    </p>
                    <v-radio-group
                        v-model="item.agree"
                        row
                        @change="updateAgree(item)"
                    >
                        <v-radio
                            label="동의"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            label="비동의"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile & !loading"
            id="capture"
            class="pa-2 rounded-15"
            style="position:relative; background:white;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold mb-0 pt-5 pl-4"
                style="line-height:28px;"
            >
                <span class="primary--text">마지노선</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:16px; right:16px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 본문 -->
            <v-sheet
                class="mb-8 rounded-10 pb-4 px-4 mt-12"
            >
                <!-- 마지노선 조항 -->
                <div v-for="(item, index) in clause_list" :key="index">
                    <p
                        class="mt-6 mb-2 text-body-1 font-weight-bold grey--text text--darken-2"
                        style="line-height:20px;"
                    >
                        {{index+1}}. {{item.line}}
                    </p>
                    <v-radio-group
                        v-model="item.agree"
                        row
                        @change="updateAgree(item)"
                    >
                        <v-radio
                            label="동의"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            label="비동의"
                            :value="0"
                        ></v-radio>
                    </v-radio-group>
                </div>
            </v-sheet>

            <div
                v-if="status != '수락완료'"
                class="mx-2 d-flex justify-center"
            >
                <v-btn
                    class="rounded-15 grey--text px-9 mb-2 mr-2"
                    height="48"
                    large
                    color="grey lighten-2"
                    dark
                    depressed
                    @click="refuseWishList()"
                >
                    거절하기
                </v-btn>

                <v-btn
                    
                    class="rounded-15 px-9 mb-2"
                    height="48"
                    large
                    color="primary"
                    dark
                    depressed
                    @click="agreeWishList()"
                >
                    수락하기
                </v-btn>
            </div>
        </div>

        <!-- 마지노선 완료 -->
        <v-dialog
            v-model="dialog.finish"
            width="440"
            content-class="rounded-xl"
        >
            <div
                id="capture"
            >
                <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                    <v-img
                        class="mx-auto mb-2"
                        width="80"
                        src="@/assets/logo/default.png"
                    ></v-img>
                    <p
                        class="grey--text text-center text-caption mb-1"
                    >
                        sweetdeal<br/>
                        {{new Date().toLocaleDateString()}}
                    </p>
                </v-sheet>
                <v-sheet
                    class="pt-8 pb-6"
                >
                    <p
                        class="mx-10 font-weight-medium mb-6"
                    >
                        <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님이<br/>
                        <span class="primary--text">{{new Date().toLocaleDateString()}}</span> 스윗딜을 통해<br/>
                        마지노선을 체결했어요!
                    </p>

                    <!-- 이미지 저장 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex1"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            @click="capture()"
                        >
                            SNS 공유하기
                        </v-btn>
                    </div>

                    <!-- 보낸이에게 알리기 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex2"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            @click="sendToSender()"
                        >
                            보낸이에게 알리기
                        </v-btn>
                    </div>

                    <!-- 마이페이지 이동 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex2"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            to="/mypage/dashboard"
                        >
                            마이페이지 이동
                        </v-btn>
                    </div>
                </v-sheet>
            </div>
        </v-dialog>

        <!-- 마지노선 거절 -->
        <v-dialog
            v-model="dialog.refuse"
            width="440"
            content-class="rounded-xl"
        >
            <div
                id="capture"
            >
                <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                    <v-img
                        class="mx-auto mb-2"
                        width="80"
                        src="@/assets/logo/default.png"
                    ></v-img>
                    <p
                        class="grey--text text-center text-caption mb-1"
                    >
                        sweetdeal<br/>
                        {{new Date().toLocaleDateString()}}
                    </p>
                </v-sheet>
                <v-sheet
                    class="pt-8 pb-6"
                >
                    <p
                        class="mx-10 font-weight-medium mb-6"
                    >
                        <span class="primary--text">{{sender}}</span>님의 마지노선을 거절했습니다
                    </p>

                    <!-- 보낸이에게 알리기 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex2"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            @click="sendToSenderRefuse()"
                        >
                            보낸이에게 알리기
                        </v-btn>
                    </div>

                    <!-- 마이페이지 이동 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex2"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            to="/mypage/dashboard"
                        >
                            마이페이지 이동
                        </v-btn>
                    </div>
                </v-sheet>
            </div>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="60"
        ></v-progress-circular>
    </div>
</template>
<script>
import html2canvas from 'html2canvas'

export default {
    components: {
        html2canvas
    },

    props: ["id"],

    data: () => ({
        sender: "",
        receiver: "",
        reveal: 0,
        status: "",
        clause_list: [],

        dialog: {
            finish: false
        },

        loading: true
    }),

    mounted(){
        // 마지노선 정보 가져오기
        this.load()
    },
    
    methods: {
        // 마지노선 정보 가져오기
        load(){
            this.$http.post("/api/maginotline/select/specific/id", {
                params: {
                    id: this.id
                }
            }).then((res) => {
                console.log(res)

                // 발송인, 수신인, 마지노선 상단
                this.sender = res.data[0].sender
                this.receiver = res.data[0].receiver
                this.reveal = res.data[0].reveal
                this.status = res.data[0].status

                // 조항 가져오기
                this.$http.post("/api/maginotline/select/specific/clause_list", {
                    params: {
                        maginotline_id: res.data[0].id
                    }
                }).then((res) => {
                    this.clause_list = res.data
                    this.loading = false
                })
            })
        },

        updateAgree(item){
            this.$http.post("/api/maginotline/update/line/agree", {
                params: {
                    id: item.id,
                    agree: item.agree
                }
            }).then((res) => {
                console.log(res)
            })
        },

        // 캡처
        capture(){
            this.$globalSnackbar.show("설정에서 사진 앨범 권한을 허용해주세요")
            return
            
            const captureElement = document.getElementById('capture')
            const excludeIds2 = ['ex1', 'ex2'] // 제외할 요소의 ID 목록

            // 제외할 요소 숨기기
            excludeIds2.forEach(id => {
                const element = document.getElementById(id)
                if (element) {
                    element.style.display = 'none'
                }
            })

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = "스윗딜 마지노선 체결"
                a.click()

                // 이미지와 링크 요소 제거
                document.body.removeChild(img)
                a.remove()

                // 제외한 요소 다시 표시
                excludeIds2.forEach(id => {
                    const element = document.getElementById(id)
                    if (element) {
                        element.style.display = ''
                    }
                })
            })
        },

        // 마지노선 거절하기
        refuseWishList(){
            this.$http.post("/api/maginotline/update/status/refuse", {
                params: {
                    id: this.id
                }
            }).then((res) => {
                console.log(res)
                this.dialog.finish = true
            })
        },
        
        // 마지노선 수락하기
        agreeWishList(){
            this.$http.post("/api/maginotline/update/status", {
                params: {
                    id: this.id
                }
            }).then((res) => {
                console.log(res)
                this.dialog.finish = true
            })
        },

        // 마지노선 입력 & 카카오톡 공유
        sendToSender() {
            // 카카오톡 공유
            Kakao.Share.sendDefault({
                objectType: "feed",

                content: {
                    title: '스윗딜 마지노선에 수락했어요~!',
                    description: this.sender + "님과 " + this.receiver + "님의 마지노선",
                    imageUrl: 'https://romainex.com/upload/logo.png',
                    link: {
                        mobileWebUrl: 'https://romainex.com',
                        webUrl: 'https://romainex.com',
                    }
                },

                buttons: [
                    {
                        title: '웹으로 보기',
                        link: {
                            mobileWebUrl: 'https://romainex.com',
                            webUrl: 'https://romainex.com',
                        },
                    },
                    {
                        title: '앱으로 보기',
                        link: {
                            androidExecutionParams: 'param1=value1&param2=value2', // 안드로이드 딥 링크 파라미터
                            iosExecutionParams: 'param1=value1&param2=value2', // iOS 딥 링크 파라미터
                        },
                    },
                ],

                serverCallbackArgs: {
                    key: 'value', // 사용자 정의 파라미터 설정
                },
            })

            // 마이페이지 대시보드로 이동 (5초 딜레이 후)
            setTimeout(() => {
                this.loading = false
                this.$router.push('/mypage/dashboard')
            }, 5000)
        },

        // 거절 & 카카오톡 공유
        sendToSenderRefuse() {
            // 카카오톡 공유
            Kakao.Share.sendDefault({
                objectType: "feed",

                content: {
                    title: '스윗딜 마지노선을 거절했어요',
                    description: this.sender + "님과 " + this.receiver + "님의 마지노선",
                    imageUrl: 'https://romainex.com/upload/logo.png',
                    link: {
                        mobileWebUrl: 'https://romainex.com',
                        webUrl: 'https://romainex.com',
                    }
                },

                buttons: [
                    {
                        title: '웹으로 보기',
                        link: {
                            mobileWebUrl: 'https://romainex.com',
                            webUrl: 'https://romainex.com',
                        },
                    },
                    {
                        title: '앱으로 보기',
                        link: {
                            androidExecutionParams: 'param1=value1&param2=value2', // 안드로이드 딥 링크 파라미터
                            iosExecutionParams: 'param1=value1&param2=value2', // iOS 딥 링크 파라미터
                        },
                    },
                ],

                serverCallbackArgs: {
                    key: 'value', // 사용자 정의 파라미터 설정
                },
            })

            // 마이페이지 대시보드로 이동 (5초 딜레이 후)
            setTimeout(() => {
                this.loading = false
                this.$router.push('/mypage/dashboard')
            }, 5000)
        }
    }
}
</script>