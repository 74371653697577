<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1; padding-bottom:120px;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'440px'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                오늘의 별자리 운세
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/star.png"
            ></v-img>

            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                오늘의 별자리 운세
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                나의 오늘 별자리 운세는?
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
            >
                <table
                    class="starTable px-10 mt-4"
                >
                    <tr>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 1? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 1"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/1.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        양자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 2? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 2"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/2.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        황소자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 3? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 3"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/3.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        쌍둥이자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 4? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 4"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/4.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        게자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 5? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 5"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/5.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        사자자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 6? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 6"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/6.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        처녀자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 7? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 7"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/7.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        천칭자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 8? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 8"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/8.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        전갈자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 9? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 9"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/9.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        사수자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 10? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 10"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/10.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        염소자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 11? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 11"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/11.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        물병자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                        <td>
                            <v-card
                                class="rounded-10 d-flex justify-center pt-3 pb-2"
                                flat
                                :style="star == 12? 'border:1px solid #6354b2;':'border:1px solid #e1e1e1;'"
                                @click="star = 12"
                            >
                                <div>
                                    <v-img
                                        width="40"
                                        style="margin:0 auto;"
                                        src="@/assets/ai/star/12.png"
                                    ></v-img>
                                    <p
                                        style="width:60px;"
                                        class="text-center text-caption mt-1 mb-0 font-weight-medium"
                                    >
                                        물고기자리
                                    </p>
                                </div>
                            </v-card>
                        </td>
                    </tr>
                </table>

                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="callOpenAI()"
                >
                    운세 확인하기
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
            >
                <div>
                    <v-img
                        width="80"
                        style="margin:0 auto; margin-top:16px;"
                        :src="require('@/assets/ai/star/'+star+'.png')"
                    ></v-img>
                    <p
                        style="width:80px; margin:0 auto;"
                        class="text-center text-body-2 mt-1 mb-0 font-weight-medium primary--text"
                    >
                        {{star_kr}}
                    </p>
                </div>

                <p
                    class="text-subtitle-2 mt-2 mx-8"
                >
                    {{response}}
                </p>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        birth: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,

        star: null,
        star_kr: null
    }),

    mounted(){

    },

    methods: {
        async callOpenAI() {

            if(this.star == null){
                this.$globalSnackbar.show("별자리가 선택되지 않았습니다")
                return
            }

            this.star_kr = null

            if(this.star == 1){
                this.star_kr = "양자리"
            }
            else if(this.star == 2){
                this.star_kr = "황소자리"
            }
            else if(this.star == 3){
                this.star_kr = "쌍둥이자리"
            }
            else if(this.star == 4){
                this.star_kr = "게자리"
            }
            else if(this.star == 5){
                this.star_kr = "사자자리"
            }
            else if(this.star == 6){
                this.star_kr = "처녀자리"
            }
            else if(this.star == 7){
                this.star_kr = "천칭자리"
            }
            else if(this.star == 8){
                this.star_kr = "전갈자리"
            }
            else if(this.star == 9){
                this.star_kr = "사수자리"
            }
            else if(this.star == 10){
                this.star_kr = "염소자리"
            }
            else if(this.star == 11){
                this.star_kr = "물병자리"
            }
            else if(this.star == 12){
                this.star_kr = "물고기자리"
            }

            // AI 카운팅 불러오기
            this.ai_counting()

            this.loading = true

            this.prompt = this.star_kr + "인 사람의 오늘의 운세를 작성해줘"


            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },

        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "ai_star"
                }
            }).then((res) => {
                console.log(res)
            })
        },
}
}
</script>
<style scoped>
.starTable{
    width:100%;
}

.starTable tr td{
    width:100px;
    padding: 2px;
}
</style>