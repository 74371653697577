<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1;"
        class="px-2 pb-16 mb-16"
        :width="$vuetify.breakpoint.mobile? '100%':'1000px;'"
    >
        <!-- # 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                관상 궁합
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                연인과 같이 관상 확인
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- # 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- # 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/face_match.png"
            ></v-img>

            <!-- # 제목 & 부제목 -->
            <p
                class="text-h6 text-center mb-0"
            >
                관상 궁합
            </p>
            <p
                class="text-body-2 text-center primary--text"
            >
                연인과 같이 관상 확인
            </p>

            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- # 입력 -->
            <div
                v-if="!answered && !loading"
            >
                <!-- 내 사진 입력 -->
                <v-file-input
                    style="width:200px;"
                    class="mx-auto rounded-10"
                    dense
                    hide-details
                    outlined
                    prepend-icon=""
                    color=""
                    append-icon="mdi-camera-outline"
                    @change="handleFileUpload_me"
                    @change.native="handleNativeChange"
                    accept="image/*"
                    placeholder="내 사진 선택"
                ></v-file-input>

                <!-- 내 사진 미리보기 -->
                <v-img
                    v-if="previewImage_me"
                    :src="previewImage_me"
                    class="mx-auto rounded-10 mt-2"
                    max-width="200"
                    max-height="200"
                ></v-img>

                <!-- 상대 사진 입력 -->
                <v-file-input
                    style="width:200px;"
                    class="mx-auto rounded-10 mt-2"
                    dense
                    hide-details
                    outlined
                    prepend-icon=""
                    color=""
                    append-icon="mdi-camera-outline"
                    @change="handleFileUpload_opponent"
                    @change.native="handleNativeChange2"
                    accept="image/*"
                    placeholder="상대 사진 선택"
                ></v-file-input>

                <!-- 상대 사진 미리보기 -->
                <v-img
                    v-if="previewImage_opponent"
                    :src="previewImage_opponent"
                    class="mx-auto rounded-10 mt-2"
                    max-width="200"
                    max-height="200"
                ></v-img>

                <!-- 사진 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-2 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callTotal()"
                >
                    관상 알아보기
                </v-btn>
            </div>

            <!-- # 답변 -->
            <div
                v-if="answered && !loading"
            >
                <div
                    style="width:400px; white-space: pre-line;"
                >
                    <p
                        class="text-subtitle-1 primary white--text rounded-10 px-2 mt-6"
                        style="width:100px; margin-left:-8px;"
                    >
                        <v-icon color="white" small class="mb-2px mr-1">mdi-face-man-shimmer-outline</v-icon>내 관상
                    </p>
                    {{ result_me }}
                </div>

                <div
                    style="width:400px; white-space: pre-line; margin-top:20px;"
                >
                    <p
                        class="text-subtitle-1 primary white--text rounded-10 px-2"
                        style="width:110px; margin-left:-8px;"
                    >
                        <v-icon color="white" small class="mb-2px mr-1">mdi-face-man-shimmer-outline</v-icon>상대 관상
                    </p>

                    {{ result_opponent }}
                </div>
                
                <div
                    style="width:400px; white-space: pre-line; margin-top:20px;"
                >
                    <p
                        class="text-subtitle-1 primary white--text rounded-10 px-2"
                        style="width:80px; margin-left:-8px;"
                    >
                        <v-icon color="white" small class="mb-2px mr-1">mdi-handshake-outline</v-icon>궁합
                    </p>

                    {{ result_match }}
                </div>

                <!-- 다시하기 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-10 mb-2"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="answered = false"
                >
                    다시하기
                </v-btn>
            </div>

            <!-- # 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,

        file_me: null,
        file_opponent: null,
        result_me: '',
        result_opponent: '',
        result_match: '',
        previewImage_me: null,
        previewImage_opponent: null
    }),

    methods: {
        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "ai_face_match"
                }
            }).then((res) => {
                console.log(res)
            })
        },

        // # 내 사진 저장
        handleFileUpload_me(file) {
            this.file_me = file

            if (this.file_me) {
                const reader = new FileReader()
                reader.onload = () => {
                    this.previewImage_me = reader.result
                }
                reader.readAsDataURL(this.file_me)
            }
        },

        // # 상대 사진 저장
        handleFileUpload_opponent(file) {
            this.file_opponent = file

            if (this.file_opponent) {
                const reader = new FileReader()
                reader.onload = () => {
                    this.previewImage_opponent = reader.result
                }
                reader.readAsDataURL(this.file_opponent)
            }
        },

        // ! 몰루
        handleNativeChange(event) {
            console.log(event)
        },

        // ! 몰루
        handleNativeChange2(event) {
            console.log(event)
        },

        // # 전체 요청
        async callTotal(){
            this.loading = true
            this.answered = false

            await this.request_me()
            await this.request_opponent()
            await new Promise(resolve => setTimeout(resolve, 20000))
            await this.request_match()

            this.loading = false
            this.answered = true
        },

        // # 내 관상 요청
        async request_me() {
            try {
                const reader = new FileReader()
                reader.onload = async () => {
                    const base64Image = reader.result.split(',')[1]
                    const analysisResult = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                        model: 'gpt-4o',
                        max_tokens: 2000,
                        messages: [
                            {
                                role: 'system',
                                content: '당신은 사진을 보고 관상을 봐주는 전문가입니다. 관상학적 해석에서 장단점을 객관적으로 분석해주세요.'
                            },
                            {
                                role: 'user',
                                content: [
                                    {
                                        type: 'text',
                                        text: 
                                        `
                                            이 사진 속 인물의 관상을 한국 관상학을 기준으로 분석해주세요.
                                            외모에 대한 주관적 평가 없이 관상학적 관점에서 장단점을 객관적으로 서술해주세요.
                                            인물이 누구인지는 언급할 필요 없습니다.
                                            문단을
                                            1. 얼굴 형상
                                            2. 이마
                                            3. 눈
                                            4. 코
                                            5. 입
                                            6. 귀
                                            7. 눈썹
                                            8. 턱
                                            9. 장점
                                            10. 단점
                                            11. 종합적인 평가
                                            로 나뉘어 설명해주세요
                                            단점은 없으면 없다 있으면 꼭 말해주세요
                                        `
                                    },
                                    {
                                        type: 'image_url',
                                        image_url: {
                                            url: `data:image/jpeg;base64,${base64Image}`  
                                        }
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.apiKey}`
                        }
                    })

                    // 결과값
                    this.result_me = analysisResult.data.choices[0].message.content
                }
                reader.readAsDataURL(this.file_me)
            }
            catch (error) {
                console.error('OpenAI API 호출 중 오류 발생:', error)
            }
        },

        // # 상대 관상 요청
        async request_opponent() {
            try {
                const reader = new FileReader()
                reader.onload = async () => {
                    const base64Image = reader.result.split(',')[1]
                    const analysisResult = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                        model: 'gpt-4o',
                        max_tokens: 2000,
                        messages: [
                            {
                                role: 'system',
                                content: '당신은 사진을 보고 관상을 봐주는 전문가입니다. 관상학적 해석에서 장단점을 객관적으로 분석해주세요.'
                            },
                            {
                                role: 'user',
                                content: [
                                    {
                                        type: 'text',
                                        text: 
                                        `
                                            이 사진 속 인물의 관상을 한국 관상학을 기준으로 분석해주세요.
                                            외모에 대한 주관적 평가 없이 관상학적 관점에서 장단점을 객관적으로 서술해주세요.
                                            인물이 누구인지는 언급할 필요 없습니다.
                                            문단을
                                            1. 얼굴 형상
                                            2. 이마
                                            3. 눈
                                            4. 코
                                            5. 입
                                            6. 귀
                                            7. 눈썹
                                            8. 턱
                                            9. 장점
                                            10. 단점
                                            11. 종합적인 평가
                                            로 나뉘어 설명해주세요
                                            단점은 없으면 없다 있으면 꼭 말해주세요
                                        `
                                    },
                                    {
                                        type: 'image_url',
                                        image_url: {
                                            url: `data:image/jpeg;base64,${base64Image}`  
                                        }
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.apiKey}`
                        }
                    })

                    // 결과값
                    this.result_opponent = analysisResult.data.choices[0].message.content
                }
                reader.readAsDataURL(this.file_opponent)
            }
            catch (error) {
                console.error('OpenAI API 호출 중 오류 발생:', error)
            }
        },

        // # 궁합 요청
        async request_match() {
            try {
                const prompt = `
                    아래는 두 사람의 관상 분석 결과입니다. 
                    이를 바탕으로 두 사람의 궁합을 분석해주세요.
                    문단을
                    1. 두 사람의 관상이 서로 어울리는 점
                    2. 두 사람의 관상이 서로 부족한 점
                    3. 궁합 총평
                    로 나뉘어 분석 결과를 제시해주세요.

                    첫 번째 사람 관상:
                    ${this.result_me}

                    두 번째 사람 관상: 
                    ${this.result_opponent}
                `

                const analysisResult = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-4o',
                    max_tokens: 2000,
                    messages: [
                        {
                            role: 'system',
                            content: '당신은 두 사람의 관상 분석 결과를 토대로 궁합을 분석하는 전문가입니다.'
                        },
                        {
                            role: 'user',
                            content: prompt
                        }
                    ]
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })

                // 결과값
                this.result_match = analysisResult.data.choices[0].message.content
                this.ai_counting()
            } catch (error) {
                console.error('OpenAI API 호출 중 오류 발생:', error)
            }
        }
    }
}
</script> 