<template>
    <div>
        <v-list
            nav
            class="font-weight-medium text-body-2 px-2 mt-2"
        >
            <v-list-item
                v-for="(item, index) in menu" :key="index"
                :to="item.url"
                class="px-4"
                exact
                dense
                active-class="primary white--text"
            >
                {{item.title}}
            </v-list-item>
        </v-list>

        <v-divider class="mx-6 mb-2"></v-divider>

        <v-list
            nav
            class="font-weight-medium text-body-2 px-2"
        >
            <v-list-item
                v-for="(item, index) in menu2" :key="index"
                :to="item.url"
                class="px-4"
                exact
                dense
                active-class="primary white--text"
            >
                {{item.title}}
                <span class="ml-1" v-if="item.title == '쳬결된 계약' && list.on.length">({{list.on.length}})</span>
                <span class="ml-1" v-if="item.title == '내 서명 대기중' && list.mysignwaiting.length">({{list.mysignwaiting.length}})</span>
                <span class="ml-1" v-if="item.title == '상대방 서명 대기중' && list.partnersignwaiting.length">({{list.partnersignwaiting.length}})</span>
            </v-list-item>
        </v-list>

        <v-divider class="mx-6 mt-2 mb-4"></v-divider>

        <v-list
            nav
            class="font-weight-medium text-body-2 px-2"
        >
            <v-list-item
                v-for="(item, index) in menu3" :key="index"
                :to="item.url"
                class="px-4"
                exact
                dense
                active-class="primary white--text"
            >
                {{item.title}}
                <span class="ml-1" v-if="item.title == '쳬결된 위시리스트' && wishlist.on.length">({{wishlist.on.length}})</span>
                <span class="ml-1" v-if="item.title == '내 수락 대기중' && wishlist.mysignwaiting.length">({{wishlist.mysignwaiting.length}})</span>
                <span class="ml-1" v-if="item.title == '상대방 수락 대기중' && wishlist.partnersignwaiting.length">({{wishlist.partnersignwaiting.length}})</span>
            </v-list-item>
        </v-list>

        <v-divider class="mx-6 mt-2 mb-4"></v-divider>

        <v-list
            nav
            class="font-weight-medium text-body-2 px-2"
        >
            <v-list-item
                v-for="(item, index) in menu4" :key="index"
                :to="item.url"
                class="px-4"
                exact
                dense
                active-class="primary white--text"
            >
                {{item.title}}
                <span class="ml-1" v-if="item.title == '쳬결된 마지노선' && maginotline.on.length">({{maginotline.on.length}})</span>
                <span class="ml-1" v-if="item.title == '내 수락 대기중' && maginotline.mysignwaiting.length">({{maginotline.mysignwaiting.length}})</span>
                <span class="ml-1" v-if="item.title == '상대방 수락 대기중' && maginotline.partnersignwaiting.length">({{maginotline.partnersignwaiting.length}})</span>
            </v-list-item>
        </v-list>

        <v-divider class="mx-6 mt-2 mb-4"></v-divider>

        <v-list
            nav
            class="font-weight-medium text-body-2 px-2"
        >
            <v-list-item
                v-for="(item, index) in menu5" :key="index"
                :to="item.url"
                class="px-4"
                exact
                dense
                active-class="primary white--text"
            >
                {{item.title}}
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
export default {
    data: () => ({
        menu: [
            {
                title: "메인",
                url: "/"
            },
        ],

        menu2: [
            {
                title: "쳬결된 계약",
                url: "/mypage/list?type=ongoing"
            },
            {
                title: "종료된 계약",
                url: "/mypage/list?type=end"
            },
            {
                title: "내 서명 대기중",
                url: "/mypage/list?type=mysignwaiting"
            },
            {
                title: "상대방 서명 대기중",
                url: "/mypage/list?type=partnersignwaiting"
            },
        ],

        menu3: [
            {
                title: "쳬결된 위시리스트",
                url: "/mypage/wishlist?type=ongoing"
            },
            {
                title: "내 수락 대기중",
                url: "/mypage/wishlist?type=mysignwaiting"
            },
            {
                title: "상대방 수락 대기중",
                url: "/mypage/wishlist?type=partnersignwaiting"
            },
        ],

        menu4: [
            {
                title: "쳬결된 마지노선",
                url: "/mypage/maginotline?type=ongoing"
            },
            {
                title: "내 수락 대기중",
                url: "/mypage/maginotline?type=mysignwaiting"
            },
            {
                title: "상대방 수락 대기중",
                url: "/mypage/maginotline?type=partnersignwaiting"
            },
        ],

        menu5: [
            {
                title: "고객센터",
                url: "/mypage/cs"
            },
            {
                title: "회원정보 변경",
                url: "/mypage/update"
            },
        ],

        list: {
            mysignwaiting: [],
            partnersignwaiting: [],
            on: []
        },

        wishlist: {
            mysignwaiting: [],
            partnersignwaiting: [],
            on: []
        },

        maginotline: {
            mysignwaiting: [],
            partnersignwaiting: [],
            on: []
        },
    }),

    mounted(){
        this.loadContract()

        // 위시리스트 전체 불러오기
        this.loadWishList()

        // 마지노선 전체 불러오기
        this.loadMaginotline()
    },
    
    methods: {
        loadContract(){
            this.$http.post("/api/contract/select")
            .then((res) => {
                // console.log(res)
                if(res.data.length){
                    this.list.mysignwaiting = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.partnersignwaiting = res.data.filter(e => e.sender == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')
                    this.list.on = res.data.filter(e => e.status == '계약완료' && (e.sender == this.$store.state.sweetdeal_user.name || e.receiver == this.$store.state.sweetdeal_user.name))
                }
            })
        },

        // 위시리스트 전체 불러오기
        loadWishList(){
            this.$http.post("/api/wishlist/select")
            .then((res) => {
                console.log(res)
                if(res.data.length){
                    this.wishlist.mysignwaiting = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '수락대기중')
                    this.wishlist.partnersignwaiting = res.data.filter(e => e.sender == this.$store.state.sweetdeal_user.name && e.status == '수락대기중')
                    this.wishlist.on = res.data.filter(e => e.status == '수락완료' && (e.sender == this.$store.state.sweetdeal_user.name || e.receiver == this.$store.state.sweetdeal_user.name))
                }
            })
        },

        // 마지노선 전체 불러오기
        loadMaginotline(){
            this.$http.post("/api/maginotline/select")
            .then((res) => {
                console.log(res)
                if(res.data.length){
                    this.maginotline.mysignwaiting = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '수락대기중')
                    this.maginotline.partnersignwaiting = res.data.filter(e => e.sender == this.$store.state.sweetdeal_user.name && e.status == '수락대기중')
                    this.maginotline.on = res.data.filter(e => e.status == '수락완료' && (e.sender == this.$store.state.sweetdeal_user.name || e.receiver == this.$store.state.sweetdeal_user.name))
                }
            })
        },

        link(url){
            this.$router.push(url)
        }
    }
}
</script>