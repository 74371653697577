<template>
    <v-sheet
        style="margin:0 auto;"
        :width="$vuetify.breakpoint.mobile? '100%':'1200'"
    >
        <!-- 로그인 상태 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 mt-6"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                이벤트 페이지
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10 mx-2">
            <!-- 상단 -->
            <v-sheet
                class="py-3 px-4 d-flex align-end"
            >
                <p
                    style="width:440px; font-size:18px;"
                    class="ma-0 text-truncate"
                >
                    <!-- 제목 -->
                    <span class="font-weight-bold">
                        {{title}}
                    </span>
                    <br/>
                </p>
                <v-spacer></v-spacer>
            </v-sheet>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>
            <TipTapReader
                v-if="options.content"
                class="tiptap_style pa-4"
                :options="options"
            />
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapReader from "@/components/tiptap/Reader"

export default {
    components: {
        TipTapReader,
    },

    data: () => ({
        title: "",

        //TipTap
        options: {
            content: "",
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },
    }),

    mounted(){
        // 게시글 정보 가져오기
        this.$http.post("/api/event/select/specific", {
            params: {
                id: this.$route.query.id
            }
        }).then((res) => {
            this.title = res.data[0].title
            this.options.content = res.data[0].content
        })
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}

.v-btn::before {
    background-color: transparent;
}
</style>