<template>
    <div
        class="pb-16"
    >
        <v-sheet
            class="elevation-0 pt-2 pb-14 px-4"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 ml-4 mb-2 primary--text mt-6 font-weight-bold"
            >
                회원가입
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 인증번호 받기 -->
            <v-btn
                block
                class="mt-14 rounded-10"
                height="48"
                large
                :color="$route.query.status == 'checked'? 'grey lighten-2':'primary'"
                dark
                depressed
                @click="KGMobilians_SendMessage()"
            >
                인증번호 받기
            </v-btn>

            <!-- 개인정보 처리방침 -->
            <p
                class="text-caption mt-1 mb-2 grey--text text-end"
                style="cursor:pointer;"
                @click="$router.push('/policy/privacy')"
            >
                개인정보 처리방침
            </p>

            <!-- 이름 -->
            <v-text-field
                outlined
                style="background:white;"
                class="rounded-lg mb-6"
                label="이름"
                hide-details
                v-model="name"
                @change="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :error-messages="name_Errors"
            ></v-text-field>

            <!-- 비밀번호 -->
            <v-text-field
                outlined
                style="background:white;"
                class="rounded-lg"
                label="비밀번호"
                type="password"
                hide-details
                v-model="password"
                @change="$v.password.$touch()"
                @blur="$v.password.$touch()"
                :error-messages="password_Errors"
            ></v-text-field>

            <!-- 비밀번호 조건 -->
            <p class="ml-2 text-caption">
                *특수문자, 숫자 포함 8자리 이상
            </p>

            <!-- 비밀번호 확인 -->
            <v-text-field
                outlined
                style="background:white;"
                class="rounded-lg"
                label="비밀번호 확인"
                type="password"
                hide-details
                v-model="password_check"
                @change="$v.password_check.$touch()"
                @blur="$v.password_check.$touch()"
                :error-messages="password_check_Errors"
            ></v-text-field>

            <!-- 약관동의 -->
            <p
                class="text-body-2 mt-6 mb-0 font-weight-medium"
            >
                약관동의
            </p>

            <!-- 모두 동의 -->
            <v-checkbox
                dense
                hide-details
                label="모두 동의하기"
                v-model="total"
                @change="switchTotal()"
            ></v-checkbox>

            <!-- 만 14세 동의 -->
            <v-checkbox
                dense
                hide-details
                label="(필수) 만 14세 이상입니다."
                v-model="teenage"
                @click="checkTotal()"
                required
                :error-messages="teenage_Errors"
                @change="$v.teenage.$touch()"
                @blur="$v.teenage.$touch()"
            ></v-checkbox>
            
            <!-- 이용약관 동의 -->
            <div class="d-flex">
                <v-checkbox
                    dense
                    hide-details
                    label="(필수) 이용약관 동의"
                    v-model="rule"
                    @click="checkTotal()"
                    :error-messages="rule_Errors"
                    required
                    @change="$v.rule.$touch()"
                    @blur="$v.rule.$touch()"
                ></v-checkbox>
                <v-btn
                    class="ml-4 mt-6px"
                    color="primary"
                    text
                    small
                    @click="bottom_sheet.rule = true"
                >
                    약관보기
                </v-btn>
            </div>

            <!-- 개인정보 수집 및 이용 동의 -->
            <div class="d-flex">
                <v-checkbox
                    dense
                    hide-details
                    label="(필수) 개인정보 수집 및 이용 동의"
                    v-model="privacy"
                    @click="checkTotal()"
                    :error-messages="privacy_Errors"
                    required
                    @change="$v.privacy.$touch()"
                    @blur="$v.privacy.$touch()"
                ></v-checkbox>
                <v-btn
                    class="ml-4 mt-6px"
                    color="primary"
                    text
                    small
                    @click="bottom_sheet.privacy = true"
                >
                    약관보기
                </v-btn>
            </div>

            <!-- 마케팅 활용 및 광고성 정보 수신 동의 -->
            <div class="d-flex">
                <v-checkbox
                    dense
                    hide-details
                    label="(선택) 마케팅 활용 및 광고성 정보 수신 동의"
                    v-model="marketing"
                    @change="checkTotal(); switchMarketing()"
                ></v-checkbox>
                <v-btn
                    class="ml-4 mt-6px"
                    color="primary"
                    text
                    small
                    @click="bottom_sheet.marketing = true"
                >
                    약관보기
                </v-btn>
            </div>
            
            <!-- 마케팅 > 카카오톡, SMS, 앱 PUSH -->
            <div class="d-flex mt-2">
                <v-checkbox
                    class="mx-2"
                    dense
                    hide-details
                    label="카카오톡"
                    v-model="kakao"
                    @change="checkTotal(); switchMarketing()"
                ></v-checkbox>
                <v-checkbox
                    class="mx-2"
                    dense
                    hide-details
                    label="SMS"
                    v-model="sms"
                    @change="checkTotal(); switchMarketing()"
                ></v-checkbox>
                <v-checkbox
                    class="mx-2"
                    dense
                    hide-details
                    label="앱 PUSH"
                    v-model="app"
                    @change="checkTotal(); switchMarketing()"
                ></v-checkbox>
            </div>

            <br />

            <!-- 확인 버튼 -->
            <v-btn
                :disabled="loading"
                class="mt-4"
                large
                depressed
                dark
                color="primary"
                block
                @click="submit()"
            >
                확인
            </v-btn>
        </v-sheet>

        <!-- 이용약관 (bottom-sheet) -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.rule"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <Rule />
            </v-sheet>
        </v-bottom-sheet>

        <!-- 개인정보처리방침 (bottom-sheet) -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.privacy"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <Privacy />
            </v-sheet>
        </v-bottom-sheet>

        <!-- 마케팅 활용 동의 (bottom-sheet) -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.marketing"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <Marketing />
            </v-sheet>
        </v-bottom-sheet>

        <!-- 회원가입 완료 (dialog) -->
        <v-dialog
            v-model="dialog.complete"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-4 pt-8 pb-6">
                <!-- 제목 -->
                <p
                    class="mb-6 font-weight-bold text-center"
                    style="font-size:24px;"
                >
                    회원가입 완료
                </p>

                <v-img
                    class="mx-auto"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>
                <p class="text-center mt-6 font-weight-medium">
                    {{name}} 회원님!<br/>
                    환영합니다.
                </p>

                <p class="text-center mt-6 mb-12 font-weight-medium">
                    연인과의 이쁜 사랑이 되시길<br/>
                    응원하겠습니다.
                </p>

                <!-- 버튼 -->
                <v-btn
                    class="mt-4 rounded-10"
                    large
                    depressed
                    dark
                    color="primary"
                    block
                    to="/auth/login"
                >
                    확인
                </v-btn>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import Rule from '@/views/policy/rule'
import Privacy from '@/views/policy/privacy'
import Marketing from '@/views/policy/marketing'
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
    components: {
        Rule,
        Privacy,
        Marketing
    },

    mixins: [validationMixin],

    validations: {
        name: {
            required
        },

        password: {
            required,
            minLength: minLength(8)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password')
        },

        teenage: {
            checked (val) {
                return val
            }
        },

        rule: {
            checked (val) {
                return val
            }
        },

        privacy: {
            checked (val) {
                return val
            }
        }
    },

    data: () => ({
        name:"",
        password: "",
        password_check: "",

        // 핸드폰 인증버튼 눌렀었는지
        kg_clicked: false,

        // 전체 체크
        total: false,

        // 마케팅 전체 체크
        marketing: false,

        teenage: false,
        rule: false,
        privacy: false,
        kakao: false,
        sms: false,
        app: false,


        bottom_sheet: {
            rule: false,
            privacy: false,
            marketing: false,
        },

        dialog: {
            complete: false
        },

        loading: false
    }),

    computed: {
        // 이름 문구
        name_Errors () {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('이름을 입력해주세요')
            return errors
        },

        // 비밀번호 Error 문구
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },

        // 만 14세 Error 문구
        teenage_Errors () {
            const errors = []
            if (!this.$v.teenage.$dirty) return errors
            !this.$v.teenage.checked && errors.push('만 14세 이상에 동의해주세요')
            return errors
        },

        // 이용약관  Error 문구
        rule_Errors () {
            const errors = []
            if (!this.$v.rule.$dirty) return errors
            !this.$v.rule.checked && errors.push('이용약관에 동의해주세요')
            return errors
        },

        // 개인정보처리방침  Error 문구
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보처리방침에 동의해주세요')
            return errors
        },
    },

    mounted(){
        // 기존 아이디 검색
        this.loadUserIdList()
    },

    methods: {
        // 핸드폰 본인인증
        KGMobilians_SendMessage(){
            this.kg_clicked = true
            this.$router.push('/ci_web')
        },

        // 기존 아이디 검색
        async loadUserIdList(){
            await this.$http.post('/api/user/select')
            .then((res) => {
                this.user_id_list = res.data.map(e => e.user_id)
            })
        },

        // 전체 동의 스위치
        switchTotal(){
            if(this.total){
                this.teenage = true
                this.rule = true
                this.privacy = true
                this.marketing = true
                this.kakao = true
                this.sms = true
                this.app = true
            }else{
                this.teenage = false
                this.rule = false
                this.privacy = false
                this.marketing = false
                this.kakao = false
                this.sms = false
                this.app = false
            }
        },

        // 모두 동의했을떄 전체 동의 스위치에 적용
        checkTotal(){
            if(this.teenage && this.rule && this.privacy && this.marketing && this.kakao && this.sms && this.app){
                this.total = true
            }else{
                this.total = false
            }
        },

        // 마케팅 전체 동의 스위치
        switchMarketing(){
            if(this.marketing){
                this.kakao = true
                this.sms = true
                this.app = true
            }else{
                this.kakao = false
                this.sms = false
                this.app = false
            }
        },

        // 한개라도 동의헀을때 전체 동의 스위치에 적용
        checkMarketing(){
            if(this.kakao || this.sms || this.app){
                this.marketing = true
            }else{
                this.marketing = false
            }
        },

        // 가입하기
        submit: _.debounce(async function() {
            this.loading = true

            // 입력값 기본 검증
            this.$v.$touch()

            // 기존 아이디 검색
            await this.loadUserIdList()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // user_id 이미 있는지 확인
                if(this.user_id_list.includes(this.user_id))
                {
                    this.$globalSnackbar.show("입력하신 전화번호는 이미 가입되어있습니다.")
                    this.loading = false
                }
                else
                {
                    // 본인인증에 이름 있는지 확인
                    this.$http.post('/api/simple/signup_check/specific/name', {
                        params: {
                            user_name: this.name
                        }
                    }).then((res) => {
                        if(!res.data){
                            this.$globalSnackbar.show("본인인증을 하지않았거나 이름과 본인인증 값이 다릅니다. ")
                            this.loading = false
                        }else{
                            // 회원정보 입력
                            this.$http.post('/api/user/insert', {
                                params: {
                                    user_id: res.data[0].phone,
                                    password: this.password,
                                    name:this.name,
                                    birth:res.data[0].birth,
                                    marketing_kakao: this.kakao,
                                    marketing_sms: this.sms,
                                    marketing_app: this.app,
                                }
                            }).then(res => {
                                if(res.data.affectedRows){
                                    this.dialog.complete = true
                                    this.loading = false
                                }
                            })
                        }
                    })
                }   
            }
            else{
                this.$globalSnackbar.show("잘못된 입력값입니다.")
                this.loading = false
            }
        }, 500)
    }
}
</script>