<template>
    <div>
        <v-sheet
            v-if="!loading"
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <!-- 목록 -->
            <v-sheet
                v-if="list.length"
                class="py-4 pt-12 rounded-xl"
                color="transparent"
            >
                <v-sheet
                    v-for="(item, index) in list" :key="index"
                    class="py-4 rounded-xl mb-3"
                    style="position:relative;"
                    @click="viewContract(item)"
                >
                    <p
                        class="mx-4 font-weight-medium mb-0 pl-4 text-body-2"
                        style="line-height:22px; cursor:pointer;"
                    >
                        <span class="primary--text">{{replaceSecondChar(item.sender)}}</span>님과 <span class="primary--text">{{replaceSecondChar(item.receiver)}}</span>님께서<br/>
                        {{new Date(item.created).toLocaleString()}}<br/> 마지노선을 작성했어요<br/>
                    </p>
                    <v-img
                        style="position:absolute; top:14px; right:20px;"
                        width="40"
                        src="@/assets/logo/default.png"
                    ></v-img>
                </v-sheet>
            </v-sheet>

            <!-- 목록 없을 시 -->
            <div
                v-else
                class="pt-16"
            >
                <v-sheet
                    class="py-6 rounded-xl"
                >
                    <p
                        class="text-center text-body-2 font-weight-bold grey--text mb-0"
                    >
                        아직 공개된 마지노선이 없습니다
                    </p>
                </v-sheet>
            </div>
        </v-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="60"
        ></v-progress-circular>

        <v-dialog
            v-model="dialog.viewer"
            width="680"
            content-class="rounded-10"
        >
            <MaginotlineViewer
                :id="viewer_id"
                :key="componentKey"
            />
        </v-dialog>
    </div>
</template>
<script>
import MaginotlineViewer from "@/components/MaginotlineViewer"

export default {
    components: {
        MaginotlineViewer
    },

    data: () => ({
        list: [],

        viewer_id: 0,
        componentKey: 0,

        loading:true,

        dialog: {
            viewer: false
        }
    }),

    mounted(){
        this.load()
    },
    
    methods: {
        load(){
            this.$http.post("/api/maginotline/select/reveal")
            .then((res) => {
                console.log(res)
                this.list = res.data
                this.loading = false
            })
        },

        replaceSecondChar(str) {
            if (str.length < 2) return str
            return str.substring(0, 1) + '*' + str.substring(2)
        },

        // 계약서 상세보기
        viewContract(item){
            this.viewer_id = item.id
            this.componentKey++
            this.dialog.viewer = true
        }
    },
}
</script>