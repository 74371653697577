<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1; z-index:999;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'1000px;'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                글 보내기 AI
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                테스트
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/cheer.png"
            ></v-img>

            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                글 보내기 AI
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                테스트
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
            >
                <!-- 응원 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callOpenAI('응원')"
                >
                    응원이 되는 글
                </v-btn>

                <!-- 동기부여 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-2 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callOpenAI('동기부여')"
                >
                    동기부여가 되는 글
                </v-btn>

                <!-- 행동경제학 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callOpenAI('행동경제학')"
                >
                    행동경제학 관련 글
                </v-btn>

                <!-- 인간심리학 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-2 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callOpenAI('인간심리학')"
                >
                    인간심리학 관련 글
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-subtitle-1 font-weight-bold primary--text mt-6 px-4"
            >
                {{type}}
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-caption mt-2 px-4"
                style="width:400px; white-space: pre-line;"
            >
                {{response}}
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-caption mt-4"
            >
                <!-- 다시하기 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-10 mb-2"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="answered = false"
                >
                    다시하기
                </v-btn>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,
    }),

    methods: {
        async callOpenAI(type) {
            this.type = type

            this.loading = true

            if(type == "응원"){
                this.prompt = "힘이 되는 글을 400자로 적어줘"
                this.prompt += " 명언이나 좋은 말들을 인용해서 해줘"
            }
            else if(type == "동기부여"){
                this.prompt = "동기부여가 되는 글을 400자로 적어줘"
                this.prompt += " 명언이나 좋은 말들을 인용해서 해줘"
            }
            else if(type == "행동경제학"){
                this.prompt = "행동경제학 관련 글을 400자로 적어줘"
                this.prompt += " 행동경제학 자체를 설명하는건 첫 문단에서만 하고 그 다음 문단부터는 행동경제학의 예시들을 적어줘"
            }
            else if(type == "인간심리학"){
                this.prompt = "인간심리학 관련 글을 400자로 적어줘"
                this.prompt += " 인간심리학 자체를 설명하는건 첫 문단에서만 하고 그 다음 문단부터는 인간심리학의 예시들을 적어줘"
            }

            this.prompt += " 그리고 3개의 문단으로 나눠서 문단 사이에는 빈줄을 넣어줄래?"

            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                console.log(this.response)
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },
    }
}
</script> 