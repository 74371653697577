import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import ResizableImageTemplate2 from './ResizableImageTemplate2.vue'

export default Node.create({
	name: 'Vote',

	group: 'block',

	atom: true,

  	addAttributes() {
		// Return an object with attribute configuration
		return {
			...this.parent?.(),

			// id 속성 추가
            id: {
                default: null,
            }
		}
	},

	parseHTML() {
		return [
		{
			tag: 'Vote',
		},
		]
	},

	mounted(){
		console.log(this.$route)
	},

	renderHTML({ HTMLAttributes }) {
		return ['Vote', mergeAttributes(HTMLAttributes)]
	},

	addNodeView() {
		return VueNodeViewRenderer(ResizableImageTemplate2)
	},
})