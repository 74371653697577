<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1; z-index:999;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'1000px;'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                명언 설명 AI
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                테스트
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/cheer.png"
            ></v-img>

            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                명언 설명 AI
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                테스트
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
                class="d-flex justify-center"
            >
                <!-- 힐링 -->
                <v-btn
                    class="rounded-10"
                    color="primary"
                    width="90"
                    dark
                    depressed
                    @click="callOpenAI('힐링')"
                >
                    힐링
                </v-btn>

                <!-- 자기계발 -->
                <v-btn
                    class="rounded-10 ml-1"
                    color="primary"
                    width="90"
                    dark
                    depressed
                    @click="callOpenAI('자기계발')"
                >
                    자기계발
                </v-btn>

                <!-- 성공 -->
                <v-btn
                    class="rounded-10 ml-1"
                    color="primary"
                    width="90"
                    dark
                    depressed
                    @click="callOpenAI('성공')"
                >
                    성공
                </v-btn>

                <!-- 경제 -->
                <v-btn
                    class="rounded-10 ml-1"
                    color="primary"
                    width="90"
                    dark
                    depressed
                    @click="callOpenAI('경제')"
                >
                    경제
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-subtitle-1 font-weight-bold primary--text mt-6 px-4"
            >
                {{type}}
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="mt-2 px-4"
            >
                <p
                    class="text-subtitle-2 font-weight-bold mb-1"
                >
                    {{quote}}
                </p>
                <p
                    class="grey--text mb-2"
                    style="font-size:10px !important; font-style: italic;"
                >
                    {{quote_origin}}
                </p>
                <p
                    class="text-subtitle-2 font-weight-bold text-right mb-6"
                >
                    - &nbsp;{{author}}({{author_origin}})
                </p>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-caption mt-2 px-4"
                style="width:400px; white-space: pre-line;"
            >
                {{explain}}
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
                class="text-caption mt-4"
            >
                <!-- 다시하기 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-10 mb-2"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="answered = false"
                >
                    다시하기
                </v-btn>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        prompt2: '',
        response1: '',
        response2: '',
        answered: false,
        loading: false,

        quote_origin: "",
        author_origin: "",

        quote: "",
        author: "",

        discardedQuotes: []
    }),

    methods: {
        async addQuote(quote){
            await this.$http.post("/api/quote/insert", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id,
                    quote: quote,
                }
            }).then((res => {
                console.log("add", res)
            }))
        },

        async loadQuoteList(){
            await this.$http.post("/api/quote/select", {
                params: {
                    user_id: this.$store.state.sweetdeal_user.user_id
                }
            }).then((res => {
                console.log("load", res)
                if(res.data.length){
                    this.discardedQuotes = res.data
                }else{
                    this.discardedQuotes = []
                }
            }))
        },

        async loop(type) {
            let category
            this.type = type
            this.loading = true

            if(type == "힐링"){
                category = "hope"
            }
            else if(type == "자기계발"){
                category = "motivation"
            }
            else if(type == "성공"){
                category = "success"
            }
            else if(type == "경제"){
                category = "money"
            }

            await this.loadQuoteList()

            let quote = ''
            let author = ''
            let response = {}

            try {
                while (true) {
                    quote = ''
                    author = ''

                    await new Promise(resolve => setTimeout(resolve, 2000))

                    response = await this.$http.get('https://api.api-ninjas.com/v1/quotes?category=' + category, {
                        headers: {
                            'X-Api-Key': 'fg+3Qonx/CVippl44ab9Pg==woOe5WuAQezWd3XA'
                        }
                    })

                    console.log(response)

                    if(response.data.length){
                        quote = response.data[0].quote
                        author = response.data[0].author

                        if (!this.discardedQuotes.includes(quote)) {
                            break
                        }
                    }
                }
            } catch (error) {
                console.error('API 호출 중 오류 발생:', error)
                // 필요한 경우 추가 오류 처리 로직을 여기에 작성
            }

            // 폐기할 명언이 아닌 값을 얻었으므로 이후 로직 수행
            this.quote_origin = quote
            this.author_origin = author
                    
            console.log(this.quote_origin)
            console.log(this.author_origin)

            if(this.quote_origin){
                this.addQuote(this.quote_origin)
            }
        },

        async callOpenAI(type) {
            if(!this.$store.state.sweetdeal_user.is_logined){
                alert("로그인 이후 이용가능합니다")
                this.$router.push("/auth/login")
                return
            }

            this.quote_origin = ""
            this.author_origin = ""
            this.prompt = ""

            // # 명언 체크
            await this.loop(type)

            // # 명언 번역
            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: "'" + this.quote_origin + "'를 한글로 번역해줘" }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.quote = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }

            // # 작자 번역
            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: "'" + this.author_origin + "'는 이름인데 한글로 번역해줘" }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.author = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }

            // # 명언 설명
            this.prompt += "author : " + this.author_origin
            this.prompt += "quote : " + this.quote_origin
            this.prompt += "\n"
            this.prompt += "위에 갖고온 데이터는 명언인데 한국어로 해당 명언에 대해 200자 이내로 설명해줄래?"

            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0.7, // 0 ~ 1 : 0은 거의 고정, 1에 가까울수록 불규칙적이고 다양한 응답
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.explain = result.data.choices[0].message.content
                // .replace(/\n/g, '\n\n')
                console.log(this.explain)
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },
    }
}
</script> 