<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'440px'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                AI 연애상담
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/counseling.png"
            ></v-img>

            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                AI 연애상담
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                AI로 연애상담을 하자
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
            >
                <div
                    class="d-flex justify-space-between px-4"
                >
                    <div>
                        <!-- 제목 -->
                        <p class="text-center font-weight-medium primary--text">나</p>

                        <!-- 입력 -->
                        <v-text-field
                            style="width:136px;"
                            class="rounded-10 mx-auto mt-2"
                            v-model="name"
                            outlined
                            dense
                            hide-details
                            placeholder="이름"
                        ></v-text-field>

                        <!-- 생년월일 -->
                        <v-text-field
                            style="width:136px;"
                            class="rounded-10 mx-auto mt-3"
                            v-model="mbti"
                            outlined
                            dense
                            hide-details
                            placeholder="MBTI"
                        ></v-text-field>
                    </div>

                    <div>
                        <!-- 제목 -->
                        <p class="text-center font-weight-medium primary--text">상대</p>

                        <!-- 입력 -->
                        <v-text-field
                            style="width:136px;"
                            class="rounded-10 mx-auto mt-2"
                            v-model="other_name"
                            outlined
                            dense
                            hide-details
                            placeholder="이름"
                        ></v-text-field>

                        <!-- 생년월일 -->
                        <v-text-field
                            style="width:136px;"
                            class="rounded-10 mx-auto mt-3"
                            v-model="other_mbti"
                            outlined
                            dense
                            hide-details
                            placeholder="MBTI"
                        ></v-text-field>
                    </div>
                </div>

                <div
                    class="mt-4 px-4"
                >
                    <!-- 제목 -->
                    <p class="text-center font-weight-medium primary--text mb-2">상담내용</p>

                    <!-- 상담내용 -->
                    <v-textarea
                        class="rounded-10 mx-auto mt-2"
                        v-model="content"
                        outlined
                        dense
                        rows="3"
                        hide-details
                        placeholder="상담내용"
                    ></v-textarea>
                </div>
                
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="callOpenAI()"
                >
                    연애상담 답변받기
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
            >
                <p
                    class="text-subtitle-2 mt-4 mx-8"
                >
                    {{response}}
                </p>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        mbti: "",
        other_name: "",
        other_mbti: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,

        content: ""
    }),

    mounted(){

    },

    methods: {
        async callOpenAI() {
            if(this.name == "" || this.birth == ""){
                this.$globalSnackbar.show("입력란이 비어있습니다")
                return
            }

            // AI 카운팅 불러오기
            this.ai_counting()

            this.loading = true

            this.prompt = this.name + " MBTI" + this.mbti + "인 사람과 " + this.other_name + " MBTI" + this.other_mbti + "인 사람 사이의 일인데 여기서 " + this.name + "이 '"+this.content+"'라는 내용을 물어보는데 위에 MBTI를 활용해서 연애관련한 답변을 300자 이내로 대답해줘"


            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },

        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "ai_counseling"
                }
            }).then((res) => {
                console.log(res)
            })
        },
    }
}
</script> 