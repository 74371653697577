<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1;"
        class="px-2 pb-16 mb-16"
        :width="$vuetify.breakpoint.mobile? '100%':'1000px;'"
    >
        <!-- # 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                관상 AI
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                AI로 내 관상 확인하기
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- # 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- # 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/face.png"
            ></v-img>

            <!-- # 제목 & 부제목 -->
            <p
                class="text-h6 text-center mb-0"
            >
                관상 AI
            </p>
            <p
                class="text-body-2 text-center primary--text"
            >
                AI로 내 관상 확인하기
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- # 입력 -->
            <div
                v-if="!answered && !loading"
            >
                <v-file-input
                    style="width:200px;"
                    class="mx-auto rounded-10"
                    dense
                    hide-details
                    outlined
                    prepend-icon=""
                    color=""
                    append-icon="mdi-camera-outline"
                    @change="handleFileUpload"
                    @change.native="handleNativeChange"
                    accept="image/*"
                    placeholder="사진 선택"
                ></v-file-input>

                <!-- 이미지 미리보기 추가 -->
                <v-img
                    v-if="previewImage"
                    :src="previewImage"
                    class="mx-auto rounded-10 mt-2"
                    max-width="200"
                    max-height="200"
                ></v-img>

                <!-- 사진 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-2 mb-4"
                    color="primary"
                    width="200"
                    dark
                    depressed
                    @click="callVisionAPI()"
                >
                    관상 알아보기
                </v-btn>
            </div>

            <!-- # 답변 -->
            <div
                v-if="answered && !loading"
            >
                <!-- 결과 -->
                <div
                    style="width:400px; white-space: pre-line;"
                >
                    {{ faceReading }}
                </div>

                <!-- 다시하기 -->
                <v-btn
                    class="rounded-10 d-flex mx-auto mt-10 mb-2"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="answered = false"
                >
                    다시하기
                </v-btn>
            </div>

            <!-- # 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,

        file: null,
        faceReading: '',
        previewImage: null // 미리보기 이미지 데이터 추가
    }),

    methods: {
        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "ai_face"
                }
            }).then((res) => {
                console.log(res)
            })
        },

        handleFileUpload(file) {
            this.file = file

            if (this.file) {
                const reader = new FileReader()
                reader.onload = () => {
                    this.previewImage = reader.result
                }
                reader.readAsDataURL(this.file)
            }
        },

        handleNativeChange(event) {
            console.log(event)
        },

        async callVisionAPI() {
            this.loading = true
            this.answered = false
            try {
                const reader = new FileReader()
                reader.onload = async () => {
                    const base64Image = reader.result.split(',')[1]

                    const analysisResult = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                        model: 'gpt-4o',
                        messages: [
                            {
                                role: 'system',
                                content: '당신은 사진을 보고 관상을 봐주는 전문가입니다. 관상학적 해석에서 장단점을 객관적으로 분석해주세요.'
                            },
                            {
                                role: 'user',
                                content: [
                                    {
                                        type: 'text',
                                        text: 
                                        `
                                            이 사진 속 인물의 관상을 한국 관상학을 기준으로 분석해주세요.
                                            외모에 대한 주관적 평가 없이 관상학적 관점에서 장단점을 객관적으로 서술해주세요.
                                            인물이 누구인지는 언급할 필요 없습니다.
                                            문단을
                                            1. 얼굴 형상
                                            2. 이마
                                            3. 눈
                                            4. 코
                                            5. 입
                                            6. 귀
                                            7. 눈썹
                                            8. 턱
                                            9. 장점
                                            10. 단점
                                            11. 종합적인 평가
                                            로 나뉘어 설명해주세요
                                            단점은 없으면 없다 있으면 꼭 말해주세요
                                        `

                                    },
                                    {
                                        type: 'image_url',
                                        image_url: {
                                            url: `data:image/jpeg;base64,${base64Image}`  
                                        }
                                    }
                                ]
                            }
                        ],
                        max_tokens: 2000
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${this.apiKey}`
                        }
                    })

                    // 결과값
                    this.faceReading = analysisResult.data.choices[0].message.content

                    this.loading = false
                    this.answered = true
                    this.ai_counting()
                }
                reader.readAsDataURL(this.file)
            }
            catch (error) {
                console.error('OpenAI API 호출 중 오류 발생:', error)
            }
        }
    }
}
</script> 